<template>
  <v-card flat class="fill-height px-4">
    <v-layout class="row wrap fill-height align-center">
      <v-flex class="xs12">
        <v-card flat height="48" class="d-flex align-center">
          <div class="sw-h5">
            {{ $t("device-statistics") }}
          </div>
        </v-card>
      </v-flex>
      <v-flex class="xs12 my-2">
        <v-divider></v-divider>
      </v-flex>
      <v-flex class="xs6 mb-2">
        <span
          :style="{ lineHeight: '75px' }"
          class="sw-h2 font-weight-light sw-accent"
          >{{ totalInIos }}</span
        >
        <div class="sw-h6 font-weight-light">
          {{ $t("uses-ios") }}
        </div>
      </v-flex>
      <v-flex class="xs6 mb-2">
        <span
          :style="{ lineHeight: '75px' }"
          class="sw-h2 font-weight-light sw-accent"
          >{{ totalInAndroid }}</span
        >
        <div class="sw-h6 font-weight-light">
          {{ $t("uses-android") }}
        </div>
      </v-flex>
      <v-flex style="height: 400px; width: 100%;" class="xs12">
        <Chart :chartData="chartdata" />
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import Chart from "@/components/Charts/Chart.vue";
import variables from "@/assets/styles/variables.scss";

export default {
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    Chart,
  },
  data: () => ({
    totalInAndroid: null,
    totalInIos: null,
    statsInterval: null,
    chartdata: [],
  }),
  created() {
    this.syncStats();
    this.clearStatTimer();
    this.registerStatTimer();
  },
  methods: {
    getTotalUsersForIos() {
      const params = [
        this.groupId,
        {
          filters: [
            {
              property: "push_tokens.platform",
              operator: "in",
              value: "ios",
            },
          ],
          per_page: 1,
        },
      ];

      this.$api.groupUsers
        .list(...params)
        .then((response) => {
          if (response && this.totalInIos !== response.data.pagination.total) {
            const ios = {
              value: response.data.pagination.total,
              name: this.$t("ios"),
              itemStyle: {
                color: variables.accent,
              },
            };

            this.chartdata.push(ios);
            this.totalInIos = response.data.pagination.total;
          }
        })
        .catch(() => {});
    },
    getTotalUsersForAndroid() {
      const params = [
        this.groupId,
        {
          filters: [
            {
              property: "push_tokens.platform",
              operator: "in",
              value: "android",
            },
          ],
          per_page: 1,
        },
      ];

      this.$api.groupUsers
        .list(...params)
        .then((response) => {
          if (
            response &&
            this.totalInAndroid !== response.data.pagination.total
          ) {
            const android = {
              value: response.data.pagination.total,
              name: this.$t("android"),
              itemStyle: {
                color: variables.primaryBg,
              },
            };
            this.chartdata.push(android);
            this.totalInAndroid = response.data.pagination.total;
          }
        })
        .catch(() => {});
    },
    syncStats() {
      this.getTotalUsersForIos();
      this.getTotalUsersForAndroid();
    },
    registerStatTimer() {
      if (!this.statsInterval) {
        this.statsInterval = setInterval(this.syncStats, 15000);
      }
    },
    clearStatTimer() {
      clearInterval(this.statsInterval);
    },
  },
  beforeDestroy() {
    this.clearStatTimer();
  },
};
</script>

<style scoped>
.numbers {
  font-size: 38px;
  line-height: 100%;
  color: #01adef;
  font-weight: normal;
}
</style>
