<template>
  <v-card flat class="fill-height px-4">
    <v-layout class="row wrap fill-height align-center">
      <v-flex class="xs12">
        <v-card flat height="48" class="d-flex align-center">
          <div class="sw-h5">
            {{ $t("user-statistics") }}
          </div>
        </v-card>
      </v-flex>
      <v-flex class="xs12 my-2">
        <v-divider></v-divider>
      </v-flex>
      <v-flex class="xs6 mb-2">
        <span
          :style="{ lineHeight: '75px' }"
          class="sw-h2 font-weight-light sw-accent"
          >{{ totalGroupUsers }}</span
        >
        <div class="sw-h6 font-weight-light">
          {{ $t("users-in-group") }}
        </div>
      </v-flex>
      <v-flex class="xs6 mb-2">
        <span
          :style="{ lineHeight: '75px' }"
          class="sw-h2 font-weight-light sw-accent"
          >{{ totalInAllPlatforms }}</span
        >
        <div class="sw-h6 font-weight-light">
          {{ $t("users-visited-app") }}
        </div>
      </v-flex>
      <v-flex class="xs12 mb-2">
        <v-divider></v-divider>
      </v-flex>
      <v-flex class="xs12">
        <span
          :style="{ lineHeight: '75px' }"
          class="sw-h2 font-weight-light sw-accent"
          >{{ usersVisitedApp }}</span
        >
        <div class="sw-h6 font-weight-light">
          {{ $t("users-visited-app") }}
        </div>
      </v-flex>
      <v-flex style="height: 400px; width: 100%;" class="xs12">
        <Chart :chartData="chartdata" />
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import Chart from "@/components/Charts/Chart.vue";
import variables from "@/assets/styles/variables.scss";

export default {
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    totalGroupUsers: null,
    totalInAllPlatforms: null,
  }),
  computed: {
    usersVisitedApp() {
      const users = +Number(
        (100 / this.totalGroupUsers) * this.totalInAllPlatforms,
      ).toFixed(2);
      return !isNaN(users) ? users + "%" : "0%";
    },
    chartdata() {
      return [
        {
          value: this.totalGroupUsers - this.totalInAllPlatforms,
          name: this.$t("not-visited-app"),
          itemStyle: {
            color: variables.primaryBg,
          },
        },
        {
          value: this.totalInAllPlatforms,
          name: this.$t("visited-app"),
          itemStyle: {
            color: variables.accent,
          },
        },
      ];
    },
  },
  components: {
    Chart,
  },
  mounted() {
    this.syncStats();
    this.clearStatTimer();
    this.registerStatTimer();
  },
  methods: {
    getGroupUsers() {
      this.ready = false;

      const params = [this.groupId, { per_page: 1 }];

      this.$api.groupUsers
        .list(...params)
        .then((response) => {
          this.ready = true;
          this.totalGroupUsers = response.data.pagination.total;
        })
        .catch(() => {
          this.ready = true;
        });
    },
    getTotalUsersForAllPlatforms() {
      const params = [this.groupId, { per_page: 1 }];

      this.$api.groupUsers
        .app_users(...params)
        .then((response) => {
          this.totalInAllPlatforms = response.data.data.count;
        })
        .catch(() => {});
    },
    syncStats() {
      this.getGroupUsers();
      this.getTotalUsersForAllPlatforms();
    },
    registerStatTimer() {
      if (!this.statsInterval) {
        this.statsInterval = setInterval(this.syncStats, 15000);
      }
    },
    clearStatTimer() {
      clearInterval(this.statsInterval);
    },
  },
  beforeDestroy() {
    this.clearStatTimer();
  },
};
</script>

<style scoped>
.numbers {
  font-size: 38px;
  line-height: 100%;
  color: #01adef;
  font-weight: normal;
}
</style>
