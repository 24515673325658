<template>
  <v-card v-if="ticketsLoaded && checkinsLoaded" flat class="fill-height px-4">
    <v-layout class="row wrap fill-height align-center">
      <v-flex class="xs12">
        <v-card flat height="48" class="d-flex align-center">
          <div class="sw-h5">
            {{ $t("ticket-status") }}
          </div>
        </v-card>
      </v-flex>
      <v-flex class="xs12 my-2">
        <v-divider></v-divider>
      </v-flex>
      <v-flex class="xs6 mb-2">
        <span
          :style="{ lineHeight: '75px' }"
          class="sw-h2 sw-accent font-weight-light"
          >{{ totalTickets }}</span
        >
        <div class="sw-h6 font-weight-light">{{ $t("tickets") }}</div>
      </v-flex>
      <v-flex class="xs6 mb-2">
        <span
          :style="{ lineHeight: '75px' }"
          class="sw-h2 sw-accent font-weight-light"
          >{{ totalCheckins }}</span
        >
        <div class="sw-h6 font-weight-light">{{ $t("checked-in") }}</div>
      </v-flex>
      <v-flex class="xs12 mb-2">
        <v-divider></v-divider>
      </v-flex>
      <v-flex class="xs12">
        <span
          :style="{ lineHeight: '75px' }"
          class="sw-h2 sw-accent font-weight-light"
          >{{ checkedInUsers }}</span
        >
        <div class="sw-h6 font-weight-light">{{ $t("checked-in") }}</div>
      </v-flex>
      <v-flex style="height: 400px; width: 100%;" class="xs12">
        <Chart :chartData="chartdata"></Chart>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import Chart from "@/components/Charts/Chart.vue";
import variables from "@/assets/styles/variables.scss";

export default {
  props: {
    groupId: [String, Number],
    groupPluginId: [String, Number],
    ticketTemplateId: [String, Number],
  },
  data: () => ({
    totalTickets: null,
    totalCheckins: null,
    ticketsLoaded: false,
    checkinsLoaded: false,
    statsInterval: null,
  }),
  computed: {
    checkedInUsers() {
      const users = +Number(
        (100 / this.totalTickets) * this.totalCheckins,
      ).toFixed(2);
      return !isNaN(users) ? users + "%" : "0%";
    },
    chartdata() {
      return [
        {
          value: this.totalTickets - this.totalCheckins,
          name: this.$t("not-checked-in"),
          itemStyle: {
            color: variables.primaryBg,
          },
        },
        {
          value: this.totalCheckins,
          name: this.$t("checked-in"),
          itemStyle: {
            color: variables.accent,
          },
        },
      ];
    },
  },
  components: {
    Chart,
  },
  mounted() {
    this.syncStats();
    this.clearStatTimer();
    this.registerStatTimer();
  },
  methods: {
    listGroupTickets() {
      const params = [
        this.groupId,
        {
          group_plugin_id: this.groupPluginId,
          ticket_template_id: this.ticketTemplateId,
          per_page: 1,
        },
      ];

      this.$api.groupTickets
        .list(...params)
        .then((response) => {
          this.totalTickets = response.data.pagination.total;
          this.ticketsLoaded = true;
        })
        .catch(() => {
          this.ticketsLoaded = true;
        });
    },
    getTotalCheckedInTickets() {
      const params = [
        this.groupId,
        {
          group_plugin_id: this.groupPluginId,
          ticket_template_id: this.ticketTemplateId,
          per_page: 1,
          redeemed: 1,
        },
      ];

      this.$api.groupTickets
        .list(...params)
        .then((response) => {
          this.totalCheckins = response.data.pagination.total;
          this.checkinsLoaded = true;
        })
        .catch(() => {
          this.checkinsLoaded = true;
        });
    },
    syncStats() {
      this.listGroupTickets();
      this.getTotalCheckedInTickets();
    },
    registerStatTimer() {
      if (!this.statsInterval) {
        this.statsInterval = setInterval(this.syncStats, 15000);
      }
    },
    clearStatTimer() {
      clearInterval(this.statsInterval);
    },
  },
  beforeDestroy() {
    this.clearStatTimer();
  },
};
</script>

<style lang="scss" scoped></style>
