<template>
  <v-container class="general">
    <page-title :component="'TicketStats'"></page-title>
    <v-layout v-if="groupId && groupPluginId && ticketTemplateId" row wrap>
      <v-flex class="xs6 mb-5">
        <TicketCheckinStatus
          :group-id="groupId"
          :group-plugin-id="groupPluginId"
          :ticket-templatei-d="ticketTemplateId"
        />
      </v-flex>
      <!--
      <v-flex class="xs6 mb-5" v-if="usePhysicalToken && depositsPluginId">
        <WardrobeStatus :depositsGroupPluginId="depositsPluginId" />
      </v-flex>
      -->
      <v-flex class="xs6 mb-5">
        <AppUsersInGroup :group-id="groupId" />
      </v-flex>
      <v-flex class="xs6 mb-5">
        <PlatformStatistics :group-id="groupId" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import TicketCheckinStatus from "@/components/TicketStatsPage/TicketCheckinStatus.vue";
import AppUsersInGroup from "@/components/TicketStatsPage/AppUsersInGroup.vue";
import PlatformStatistics from "@/components/TicketStatsPage/PlatformStatistics.vue";
// import WardrobeStatus from "@/components/TicketStatsPage/WardrobeStatus.vue";

export default {
  computed: {
    ...mapGetters(["getLeftMenu"]),
    groupId() {
      return this.$route.params.group_id;
    },
    groupPluginId() {
      return this.$route.params.group_plugin_id;
    },
    ticketTemplateId() {
      return this.$route.params.ticket_template_id;
    },
    ticketTemplate() {
      if (!this.getLeftMenu.destinations.length > 0) return;
      return this.getLeftMenu.destinations
        .filter(
          (destination) =>
            destination.group_plugin_id == this.groupPluginId &&
            destination.component == "GroupTicketTemplates",
        )
        .shift();
    },
    ticketTemplateParams() {
      if (!this.ticketTemplate) return;
      return JSON.parse(this.ticketTemplate.params);
    },
    // usePhysicalToken() {
    //   if (!this.ticketTemplateParams) return;
    //   return this.ticketTemplateParams.use_physical_token ? true : false;
    // },
    depositsPluginId() {
      if (!this.ticketTemplateParams) return;
      return this.ticketTemplateParams.deposits_plugin_id;
    },
  },
  components: {
    TicketCheckinStatus,
    AppUsersInGroup,
    PlatformStatistics,
    // WardrobeStatus,
  },
};
</script>

<style>
/* SPECIFIC */
.card-icon {
  min-height: 88px;
  font-size: 56px;
  color: #22aad9;
}

.flex .v-card {
  height: 100%;
}

@media (max-width: 1024px) {
  .container.general {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 800px) {
  .flex.xs6 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>
